<template>
  <b-row>
    <b-col cols="12">
      <b-overlay
        :show="$apollo.loading"
        rounded="sm"
        spinner-variant="primary"
      >
        <b-card>
          <b-card-header
            class="justify-content-between p-0 pb-1 mb-1 border-bottom"
          >
            <div class="d-flex align-items-center p-0">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-secondary"
                @click="$router.back()"
              >
                <feather-icon
                  class="mr-25"
                  icon="ChevronLeftIcon"
                />
              </b-button>
              <h3 class="mb-0 ml-2">
                Review Stages
              </h3>
            </div>
          </b-card-header>

          <!-- search input -->
          <div class="custom-search d-flex justify-content-start">
            <b-form-group
              class="form-inline"
              label="Search"
              label-size="sm"
            >
              <b-form-input
                v-model="searchTerm"
                class="d-inline-block mr-1"
                placeholder="Search Reviews"
                type="text"
              />
            </b-form-group>
          </div>

          <!-- vue-good-table -->
          <vue-good-table
            :columns="columns"
            :pagination-options="{
              enabled: true,
              perPage: pageLength
            }"
            :rows="reviews"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm
            }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <!-- Column: Title -->
              <span v-if="props.column.field === 'title'">
                {{ props.formattedRow[props.column.field] }}
              </span>

              <!-- Column: Type -->
              <span v-else-if="props.column.field === 'type'">
                <b-badge variant="light-primary">
                  {{ props.formattedRow[props.column.field] }}
                </b-badge>
              </span>

              <!-- Column: Status -->
              <span v-else-if="props.column.field === 'review_status'">
                <b-badge :variant="statusVariant(props.formattedRow[props.column.field])">
                  {{ props.formattedRow[props.column.field] }}
                </b-badge>
              </span>

              <!-- Column: Actions -->
              <span v-else-if="props.column.field === 'action'">
                <b-button
                  v-if="props.row.review_status === 'disabled'"
                  :to="{
                    name: 'mentor-review-evaluations',
                    params: {
                      pid: $route.params.id,
                      apid: props.row.id,
                      aid: $route.params.sid,
                      rid: $route.params.pid
                    }
                  }"
                  class="mr-1"
                  size="sm"
                  variant="outline-primary"
                >
                  Report
                </b-button>
                <b-button
                  :to="{
                    name: 'mentor-review-score',
                    params: {
                      pid: $route.params.id,
                      apid: props.row.id,
                      aid: $route.params.sid,
                      rid: $route.params.pid
                    }
                  }"
                  class="mr-1"
                  size="sm"
                  variant="outline-primary"
                >
                  {{ props.row.review_status === 'disabled' ? 'View' : 'Review' }}
                </b-button>
              </span>

              <!-- Default Column -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- Pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap ">Showing 1 to</span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['25','50','100']"
                    class="mx-1"
                    @input="(value) => props.perPageChanged({ currentPerPage: value })"
                  />
                  <span class="text-nowrap"> of {{ props.total }} entries</span>
                </div>
                <div>
                  <b-pagination
                    :per-page="pageLength"
                    :total-rows="props.total"
                    :value="1"
                    align="right"
                    class="mt-1 mb-0"
                    first-number
                    last-number
                    next-class="next-item"
                    prev-class="prev-item"
                    @input="(value) => props.pageChanged({ currentPage: value })"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-card>
      </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
import {
  BBadge,
  BButton,
  BCard,
  BCardHeader,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPagination,
  BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import gql from 'graphql-tag'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    VueGoodTable,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
  },
  data() {
    return {
      pageLength: 25, // Number of rows per page
      searchTerm: '', // Search term for filtering rows
      reviews: [], // Data for the table rows
      columns: [
        { label: 'Title', field: 'title' }, // Column for title
        { label: 'Type', field: 'type' }, // Column for type
        { label: 'Status', field: 'review_status' }, // Column for review status
        { label: 'Actions', field: 'action', sortable: false }, // Column for actions, not sortable
      ],
    }
  },
  apollo: {
    reviews: {
      query() {
        return gql`
      query GetReviews($progressStageId: Int!) {
      programs_assignmenttable(order_by: {id: desc}, where: {review_status: {_is_null: false, _neq: ""}, progress_stage_id: {_eq: $progressStageId}}) {
        id
        title
        review_status
        type
      }
    }`
      },
      variables() {
        return {
          progressStageId: this.$route.params.mid,
        }
      },
      update: data => data.programs_assignmenttable, // Update the Reviews data
    },
  },
  methods: {
    /**
     * Get the variant color for the status badge.
     * @param {string} status - The status of the review.
     * @returns {string} - The variant color for the status badge.
     */
    statusVariant(status) {
      const statusColors = {
        disabled: 'light-danger',
        active: 'light-success',
        pending: 'light-warning',
      }
      return statusColors[status] || 'light-primary'
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
