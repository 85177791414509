var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-overlay',{attrs:{"show":_vm.$apollo.loading,"rounded":"sm","spinner-variant":"primary"}},[_c('b-card',[_c('b-card-header',{staticClass:"justify-content-between p-0 pb-1 mb-1 border-bottom"},[_c('div',{staticClass:"d-flex align-items-center p-0"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.$router.back()}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"ChevronLeftIcon"}})],1),_c('h3',{staticClass:"mb-0 ml-2"},[_vm._v(" Review Stages ")])],1)]),_c('div',{staticClass:"custom-search d-flex justify-content-start"},[_c('b-form-group',{staticClass:"form-inline",attrs:{"label":"Search","label-size":"sm"}},[_c('b-form-input',{staticClass:"d-inline-block mr-1",attrs:{"placeholder":"Search Reviews","type":"text"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)],1),_c('vue-good-table',{attrs:{"columns":_vm.columns,"pagination-options":{
            enabled: true,
            perPage: _vm.pageLength
          },"rows":_vm.reviews,"search-options":{
            enabled: true,
            externalQuery: _vm.searchTerm
          }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'title')?_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")]):(props.column.field === 'type')?_c('span',[_c('b-badge',{attrs:{"variant":"light-primary"}},[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])],1):(props.column.field === 'review_status')?_c('span',[_c('b-badge',{attrs:{"variant":_vm.statusVariant(props.formattedRow[props.column.field])}},[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])],1):(props.column.field === 'action')?_c('span',[(props.row.review_status === 'disabled')?_c('b-button',{staticClass:"mr-1",attrs:{"to":{
                  name: 'mentor-review-evaluations',
                  params: {
                    pid: _vm.$route.params.id,
                    apid: props.row.id,
                    aid: _vm.$route.params.sid,
                    rid: _vm.$route.params.pid
                  }
                },"size":"sm","variant":"outline-primary"}},[_vm._v(" Report ")]):_vm._e(),_c('b-button',{staticClass:"mr-1",attrs:{"to":{
                  name: 'mentor-review-score',
                  params: {
                    pid: _vm.$route.params.id,
                    apid: props.row.id,
                    aid: _vm.$route.params.sid,
                    rid: _vm.$route.params.pid
                  }
                },"size":"sm","variant":"outline-primary"}},[_vm._v(" "+_vm._s(props.row.review_status === 'disabled' ? 'View' : 'Review')+" ")])],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap "},[_vm._v("Showing 1 to")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['25','50','100']},on:{"input":function (value) { return props.perPageChanged({ currentPerPage: value }); }},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" of "+_vm._s(props.total)+" entries")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"per-page":_vm.pageLength,"total-rows":props.total,"value":1,"align":"right","first-number":"","last-number":"","next-class":"next-item","prev-class":"prev-item"},on:{"input":function (value) { return props.pageChanged({ currentPage: value }); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }